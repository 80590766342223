export const Email = ({ getValues, register, formState: { errors, touchedFields }, customError }) => {
  return <div className='flex flex-col space-y-3 p-10 pt-10'>
    <label>
      <span className="block text-xs font-medium text-slate-gray pb-1">
        Enter your email address:
      </span>
      <input name='email' id='email' type="email" className={`peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${errors.email ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} defaultValue={getValues('email') || ''} {...register('email', { required: true })} />
      {touchedFields.email && errors.email && <p className='mt-1 text-xs text-red-400'>{errors.email.message}</p>}
      {customError.message && <p className='mt-1 text-xs text-red-400'>{customError.message}</p>}
    </label>
  </div>;
}