import joi from 'joi';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

export const useEmailForm = () => {
  const schema = joi.object({
    email: joi.string().email({ tlds: { allow: false } }).required().messages({
      'string.email': 'Email must be a valid email',
      'any.required': 'Email is required',
    })
  });

  const form = useForm({
    resolver: joiResolver(schema),
  });

  return form;
}