import { useEffect, useState } from "react";

export const Card = ({ children }) => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  return <div
    className={`flex flex-col justify-center items-center w-72 bg-white p-0 rounded-3xl overflow-hidden duration-1000 transition ease-out transform ${mounted ? 'translate-y-0 opacity-100' : 'translate-y-16 opacity-0'}`} //drop-shadow-md shadow-inner
    style={{
      // boxShadow: '0px 0px 4px #8EA9F9aa, inset 0px -2px 4px #B8C9FBaa'
      boxShadow: '0px 2px 4px #17171733, inset 0px -2px 4px #17171711'
    }}
  >
    {children}
  </div>;
};