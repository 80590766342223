import { useState } from 'react';
// import { promisify } from 'util';
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails
} from 'amazon-cognito-identity-js'
import { Button } from '../../../../components/Button';
import { Card } from '../../../../components/Card';
import { Loading } from '../../../../components/Loading';

import { EmailTitle } from './Titles/Email';
import { Email } from './Cards/Email';
import { OTPTitle } from './Titles/OTP';
import { OTP } from './Cards/OTP';

import { useEmailForm } from '../../../../hooks/useEmailForm';
import { SuccessTitle } from './Titles/Success';
import { Success } from './Cards/Success';

export const AuthLinkRequest = () => {

  const emailForm = useEmailForm();
  const [customEmailError, setCustomEmailError] = useState({ message: null });
  const [customCodeError, setCustomCodeError] = useState({ message: null });

  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(null);

  const userPool = new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  });

  
  const sendOTP = async () => {
    let user = cognitoUser;
    let authDetails;

    if (!cognitoUser) {
      const email = document.getElementById('email').value;

      const userData = {
        Username: email,
        Pool: userPool,
      };
      user = new CognitoUser(userData);
      user.setAuthenticationFlowType("CUSTOM_AUTH");
  
      authDetails = new AuthenticationDetails({
        Username: email
      });
    }
    else {
      authDetails = new AuthenticationDetails({
        Username: cognitoUser.getUsername()
      });
    }
    

    return new Promise((resolve, reject) => {
      user.initiateAuth(authDetails, {
        onSuccess: (session) => {
          console.log("User exists, emailing user otp.", session);
          setCognitoUser(user);
          resolve(session);
        },
        onFailure: (err) => {
          console.log("User not registered in system.", err);
          setCustomEmailError({ message: "This email has not been registered. Please contact us to discuss the activation of this service." });
          reject(err);
        },
        customChallenge: (challengeParams) => {
          console.log("User exists, emailing user otp.", challengeParams);
          setCognitoUser(user);
          resolve(challengeParams);
        }
      });
    });
  };

  const submitCode = async () => {
    // get code
    const code = document.getElementById('code').value;

    return new Promise((resolve, reject) => {
      cognitoUser.sendCustomChallengeAnswer(code, {
        onSuccess: (session) => {
          console.log("Code was correct.");
          setCustomCodeError({ message: null });
          resolve(session);
        },
        onFailure: (err) => {
          console.log("All attempts exhausted.");
          reject(err);
        },
        customChallenge: (challengeParams) => {
          console.log("Wrong password, try again.", challengeParams);
          setCustomCodeError({ message: `Code incorrect, please try again. You have ${challengeParams.attemptsLeft} remaining attempts.` });
          resolve(challengeParams);
        }
      });
    })
  };

  const onNext = async () => {
    // User enters email
    if (step === 0) {
      try {
        setLoading(true);
        const res = await sendOTP();
        setLoading(false);
        console.log("RES:", res);
      }
      catch(err) {
        setLoading(false);
        console.log("ERROR:", err);
        return;
      } 
    }

    // User enters code emailed to them
    if (step === 1) {
      try {
        setLoading(true);
        const res = await submitCode();
        setLoading(false);
        if (res.attemptsLeft && Number(res.attemptsLeft) > 0) {
          return;
        }
        else if (res.attemptsLeft && Number(res.attemptsLeft) === 0) {
          setStep(0);
          return;
        }
        if (res.accessToken) {
          console.log("Token granted!");
          // write session to user session
          sessionStorage.setItem('easyhub-session', JSON.stringify(res));
          setStep(2);
        }
      }
      catch(err) {
        setLoading(false);
        console.log("All attempts exhausted");
        setStep(0);
        return;
      }
    }

    if (step < 1) {
      setStep(s => s + 1);
    }
    else {
      console.log("RELOAD");
      // reload page
      window.location.reload();
    }
  }
  return <>
    <div className='flex flex-col justify-center items-center space-y-6 p-3'>
      {[
        <EmailTitle />,
        <OTPTitle />,
        <SuccessTitle />,
      ][step]}
      <Card>
        {[
          <Email {...{ ...emailForm, customError: customEmailError }}/>,
          <OTP {...{ customError: customCodeError, resendCodeFn: sendOTP }}/>,
          <Success />,
        ][step]}
        {loading && <Loading />}   
      </Card>
      <Button
        hidden={!(step < 2)}
        label='Continue'
        onClick={onNext}
        disabled={!emailForm.formState.isValid || loading}
      />
    </div>
  </>;
};