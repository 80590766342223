import { useEffect, useState } from 'react';

// Loading component that says "one moment..." that animates the "..." portion on an interval.
export const Loading = () => {
  const [opacity, setOpacity] = useState(0);
  const [dots, setDots] = useState('...');
  useEffect(() => {
    setOpacity(90);
    const interval = setInterval(() => {
      setDots(dots => {
        if (dots === '...') return '.';
        if (dots === '.') return '..';
        if (dots === '..') return '...';
      });
    }, 300);

    return () => clearInterval(interval);
  }, []);

  return <div
    style={{ opacity: opacity / 100 }}
    className='w-32 h-full flex justify-start items-center transition-opacity duration-300'>
    <div className='flex flex-col items-center justify-center space-y-2'>
      <div>
        One moment{dots}
      </div>
    </div>
  </div>
};

export const Button = ({ label, onClick, disabled, type, children, hidden, loading }) => {
  if (type === 'md-black')
    return <div
      className={`flex justify-center items-center p-3 py-1 rounded-full bg-black w-full cursor-pointer transition duration-300 ${disabled ? 'opacity-50 cursor-default' : 'hover:ring-4'}`}
      onClick={!disabled ? onClick : () => { }}
    >
      <div className='flex justify-center items-center space-x-2 text-lg text-white select-none'>
        {loading ? <Loading /> :
          <>
            <div>
              {children}
            </div>
            <div>
              {label}
            </div>
          </>}
      </div>
    </div>;

  return <div
    className={`flex justify-center items-center p-3 rounded-full bg-primary w-full cursor-pointer transition duration-300 ${disabled ? 'opacity-50 cursor-default' : 'hover:ring-4'} ${hidden ? 'invisible' : ''}`}
    onClick={!disabled ? onClick : () => { }}
  >
    <div className='text-lg text-white select-none'>
      {loading ? <Loading /> : label}
    </div>
  </div>;
};