import { useState, useEffect } from 'react';
import loadingIcon from '../../assets/loading-icon.svg'

export const Loading = () => {
  const [opacity, setOpacity] = useState(0);
  useEffect(() => {
    setOpacity(90);
  }, []);

  return <div
    style={{ opacity: opacity / 100 }}
    className='absolute w-full h-full bg-white opacity-0 flex justify-center items-center transition-opacity duration-300'>
    <div className='flex flex-col items-center justify-center space-y-2'>
      <img alt='loading' src={loadingIcon} className='animate-spin w-10' />
      <div className='text-xs text-slate-gray'>
        Loading...
      </div>
    </div>
  </div >
};