import { Button } from "../../../../../components/Button";

export const OTP = ({ customError, resendCodeFn }) => {
  return <div className='flex flex-col space-y-3 p-10 pt-10'>
    <label>
      <span className="block text-xs font-medium text-slate-gray pb-1">
        Enter code that was emailed to you:
      </span>
      <input name='code' id='code' type="text" className={`peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${customError.message ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} />
      {customError.message && <p className='mt-1 text-xs text-red-400'>{customError.message}</p>}
    </label>
    <Button
      label='Resend Code'
      onClick={resendCodeFn}
    />
  </div>
};